* {
  z-index: 2;
}

html {
  height: 100%;
  width: 100%;
}

body {
  z-index: 1;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}
